<template>
  <div>
    <!-- piecesJointesList : {{ piecesJointesList }} -->
    <b-form-group
      v-for="(fileInput, index) in piecesJointesList"
      :key="index"
      :label-for="fileInput.label + index"
      :label="fileInput.label"
      class="col-12"
    >
      <!-- Styled -->
      <b-form-file
        v-model="piecesJointesList[index].value"
        placeholder="Veuillez choisir un fichier"
        drop-placeholder="Déposez ici..."
        :accept="piecesJointesList[index].type"
        :state="!isValidFile(piecesJointesList[index].value) ? false : null"
        
      />
      <!-- @change="(event) => handleFileChange(event, index)" -->
      <div>
        <small
          v-if="!isValidFile(piecesJointesList[index].value)"
          class="text-danger"
        >
          La taille de ce fichier dépasse 1Mo
        </small>
      </div>
      <!-- {{ file_for_vizualisation }} -->
      <!-- <span>
        <img
          v-if="piecesJointesList[index].fileContent"
          :src="piecesJointesList[index].fileContent"
          alt="Image"
          style="width: 100%; height: 50vh"
          class="img-fluid required-img"
        >
      </span> -->
    </b-form-group>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormFile, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "LoginButton",
  components: {
    BButton, BFormGroup, BFormFile,
  },
  directives: {
    Ripple,
  },
  watch:{
    $route: {
      immediate: true,
      deep: false,
      handler(val, old) {
        console.log('::: ⭕️⭕️⭕️', this.filesList);
        for (let index = 0; index < this.filesList.length; index++) {
          const file = this.filesList[index];
          this.piecesJointesList.push({
            label: file.label,
            key: file.key,
            type: file.type,
            // fileContent: null,
            value: null,
          })
        }
      },
    },
    piecesJointesList: {
      immediate: false,
      deep: true,
      handler(val, old) {
        // console.log('val::: 🟥', val);
          if(this.required){
            console.log('::: files are required', );
            const files_no_filtred = this.piecesJointesList.map(e => e.value)
            const allFilesAreUnempty = files_no_filtred.every(file => file !== null);            
            if(!allFilesAreUnempty){
              this.$emit("allFilesAreUnempty", false);
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title:
                    'Vous n\'avez pas renseingé tous les fichiers demandés',
                  icon: 'CheckIcon',
                  variant: 'danger',
                },
              })
              return
            }
            this.$emit("allFilesAreUnempty", true);
          }

          const files = this.piecesJointesList.map(e => e.value).filter(e => !!e)
          const allFilesAreValid = files.map(file => this.isValidFile(file))
          this.$emit("allFilesAreValid", allFilesAreValid);
          for (let index = 0; index < allFilesAreValid.length; index++) {
            const element = allFilesAreValid[index]
            if (element === false) {
              this.$emit("allFilesAreValid", false);
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title:
                    'Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 10Mo maximum',
                  icon: 'CheckIcon',
                  variant: 'danger',
                },
              })
              return
            }
          }
          this.$emit("allFilesAreValid", true);
          
         
          this.$emit("change", val);
      },
    },
  },
  props: {
    filesList: {
      type: Array,
      default: () => [],
    },
    required:  {
      type: Boolean,
      default: () => true,
    },
  },
  data(){
    return{
      piecesJointesList: [],
    }
  },

  computed: {
    isRegisterPage() {
      return this.$route.name === "operateurs-create";
    },
  },
  mounted(){ 
  },
  methods: {
    handleFileChange(event, index) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        // Mettez à jour fileContent avec l'URL de l'image
        this.piecesJointesList[index].fileContent = reader.result
      }
      // reader.readAsDataURL(file) // Lecture du contenu de l'image en tant que données URL
      console.log('this.piecesJointesList[index]::: ', this.piecesJointesList[index]);
      if (file && file.type.startsWith('image/')) {
      }
    },
    isValidFile(file) {
      if (file && file.size && file.size > 5000000) return false
      return true
    },
    emitComponentOutput() {
      this.$emit("change", { });
    },
  }
};
</script>
<style lang="scss" scoped></style>
