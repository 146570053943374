<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex flex-row flex-wrap row-gap-20">
          <h2 class="my-auto col">
            {{ $t("request_list.title") }}
          </h2>
          <b-button
            class="mt-1 float-right my-auto col-3"
            variant="primary"
            @click="
              () => {
                this.$refs['modal-option-demandes'].show();
              }
            "
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t("request_list.new_request") }}</span>
          </b-button>
        </div>

        <hr class="">

        <!-- module de recherche -->
        <!-- <app-collapse>
          <app-collapse-item title="Filtrage et exportation">
          </app-collapse-item>
        </app-collapse> -->
        <!-- filtres -->
        <b-row>
          <b-col lg="6">
            <!-- recherche par periode label-cols-xl="3"-->
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_list.filter_label_period')"
              >
                <b-input-group>
                  <DateRangeWidget
                    v-model="filterBy.periode"
                    :range="filterBy.periode"
                    placeholder="Filtrer par date"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="6">
            <!-- recherche par reference -->
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                label="Rechercher par la référence de la demande"
                :label="$t('request_list.filter_label_reference')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filterBy.reference"
                    type="text"
                    class="form-control"
                    placeholder="Entrer la référence de la demande. Exemple: qTkd5dp "
                  />
                  <b-input-group-append
                    is-text
                    @click="
                      () => {
                        filterBy.reference = null;
                        resetListWhenResetFilter();
                      }
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover.bottom="'Vider le champ!'"
                      icon="XCircleIcon"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="12">
            <!-- button -->
            <div class="d-flex flex-row-reverse nopadding">
              <b-button
                class="float-right"
                variant="warning"
                @click="getListDemandes()"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                />
                <span>{{ $t("button.search") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <!-- list -->
      <b-card class="mt-1">
        <div class="my-1">
          <b-table
            ref="refFormsTable"
            :items="tableData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune donnée"
            :busy="isLoading"
            hover
          >
            <!-- Custom Header -->
            <template #head()="{ label, field: { key, sortable } }">
              {{ $t(label) }}
            </template>
            <!-- busy -->
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- columns -->
            <template #cell(created_at)="data">
              {{ data.item.created_at }}
            </template>
            <template #cell(reference)="data">
              <span class="w-100" @click.stop="copyToClipboard(data.item.reference)">
                {{ data.item.reference }}
              </span>
            </template>
            <template #cell(type_demande)="data">
              {{
                (data.item.type_demande && data.item.type_demande.title) ||
                  data.item.type_demande_id
              }}
            </template>
            <template #cell(statuses)="data">
              <span v-if="!data.item.next_node"> <!-- don't have next node -->
                {{
                  statusMatching(
                    data.item.process_steps,
                    data.item.statuses[0].name
                  )
                }}
              </span>
              <span v-else> <!-- have next node -->
                <b-badge
                  v-if="data.item.next_node.duration"
                  pill
                  variant="light-primary"
                  class="w-100 mb-1 badge-glow py-1"
                >
                  En attent depuis
                  {{
                    calculate_passed_time_in_days(
                      data.item.statuses[0].created_at
                    )
                  }}
                  jour(s) <br>
                  Vous avez {{ data.item.next_node.duration }} jours pour
                  valider cette étape.
                </b-badge>
                
                <b-button
                  v-if="
                    !data.item.next_node.duration ||
                      (data.item.next_node.duration &&
                        calculate_passed_time_in_days(
                          data.item.statuses[0].created_at
                        ) <= data.item.next_node.duration)
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="data.item.next_node.class"
                  class="mb-75 mb-1"
                  size="sm"
                  block
                  @click="
                    update_status(data.item.id, data.item.next_node.status, data.index)
                  "
                >
                  <span>
                    <span v-if="!isLoading_update_state">
                      {{ data.item.next_node.action_label }}
                    </span>
                    <b-spinner
                      v-else
                      small
                    />
                  </span>
                </b-button>
                <span  v-if="data.item.curr_step.upload_files"> 
                  <hr class="hr-primary"/>
                  <UploadFilesComponent
                    :files-list="data.item.curr_step.upload_files"
                    @change="onFilesComponentChange"
                    @allFilesAreUnempty="(event)=>{allFilesAreUnempty = event}"
                    @allFilesAreValid="(event)=>{allFilesAreValid = event}"
                  />
                </span>
              </span>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-b-tooltip.hover.top="$t('request_manage.details_tooltip')"
                variant="primary"
                class="mx-0"
                @click="
                  $router.push({
                    name: 'formulaires-details-op',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug,
                    },
                  })
                "
              >
                <i class="las la-eye" />
              </b-button>
            </template>
          </b-table>
          <!-- pagination -->
          <div>
            <PaginationComponent
              :table-data="tableData"
              :per-page="paginationData.perPage"
              :total-rows="paginationData.total"
              @change="onPaginationChange"
            />
          </div>
        </div>
      </b-card>

      <!-- modal for notification -->
      <b-modal
        id="modal-option-demandes"
        ref="modal-option-demandes"
        hide-backdrop
        ok-only
        no-close-on-backdrop
        content-class="shadow"
        title="Quelle opération souhaitez-vous effectuer ?"
        ok-title="Continuer"
        @ok="fillForm()"
      >
        <b-form-group label="">
          <b-form-radio-group v-model="selectedDemandes">
            <b-form-radio
              v-for="(demande, index) in optionsDemandes"
              :key="index"
              name="demande"
              :value="demande"
              class="w-100"
            >
              {{ $t(demande.title) }}
              <i
                v-b-tooltip.hover.top.v-primary="demande.description"
                class="las la-info-circle ml-1"
              />
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-modal>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormRadio,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import UploadFilesComponent from '@/components/UploadFilesComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'

import formulairesStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormRadioGroup,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
    BFormRadio,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    UploadFilesComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaires', module: formulairesStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    }
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      etablissementsList: [],
      isLoadingEtablissementsList: true,
      isLoading_update_state: false,


      filterBy: {
        periode: null,
        reference: null,
        // identity: null,
      },

      periode: null,
      reference: null,
      perPage: 20,
      tableColumns: [
        {
          key: 'created_at',
          label: 'table_columns.created_at',
          sortable: true,
        },
        {
          key: 'reference',
          label: 'table_columns.reference',
          sortable: true,
        },
        {
          key: 'type_demande',
          label: 'table_columns.requests_types',
          sortable: true,
        },
        {
          key: 'statuses',
          label: 'table_columns.status',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
          sortable: true,
          thStyle: { width: '5%' },
        },
      ],

      tableData: [],
      tableDataCommissariats: [],

      filteredTableData: [],
      form_title: this.$route.params.title || '',
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      isLoading: false,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),

      optionsDemandes: null,
      selectedDemandes: null,
      upload_files_list: [],
      allFilesAreUnempty: null,
      allFilesAreValid: null,
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.init()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        // this.getListDemandes()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        // this.getListDemandes()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},

  methods: {
    ...mapActions('formulaires', {
      action_getLastFormulaireCreatedByUser: 'getLastFormulaireCreatedByUser',
      action_searchFormulaires: 'searchFormulaires',
      action_fetchFormulaires: 'fetchFormulaires',
      action_addFormulaire: 'addFormulaire',
      action_updateFormulaire: 'updateFormulaire',
      action_updateFormulaireFormData: 'updateFormulaireFormData',
    }),
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('users', {
      action_fetchUsers: 'fetchUsers',
      actions_fetchCommissariats: 'fetchCommissariats',
    }),
    // -------------------------------------------------------------------------------
    init() {
      const { listFormulairesBySector } = utilsService.currentUserUtils()
      this.optionsDemandes = listFormulairesBySector
      this.getListDemandes()
    },
    getListDemandes() {
      this.isLoading = true
      const { userData } = utilsService.currentUserUtils()
      const params = {
        ...this.filterBy,
        identity: userData.identity,
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      console.log('params::: ', params);
      this.action_searchFormulaires(params)
        .then(res => {
          // next_step_is_client_validation: "opening",
          if (res.status === 200) {
            this.isLoading = false
            this.tableData = []
            res.data.data.forEach(item => {
              // console.log('item::: ', item.statuses[0]);
              let selectedForm = null
              let curr_step = null
              let next_node = null
              selectedForm = utilsService.getFormulaireByCodeDemande(
                item.type_demande.code,
              )
              curr_step = this.get_current_step(selectedForm.process_steps, item.statuses[0].name,)
              if (curr_step && curr_step.upload_files) {
                this.upload_files_list =  curr_step.upload_files
                // console.log('this.upload_files_list::: ', this.upload_files_list);
              }

              if (curr_step && curr_step.next_step_is_client_validation) {
                next_node = this.get_next_nodes(
                  selectedForm.process_steps,
                  curr_step,
                )
              }
              console.log('selectedForm::: ', selectedForm);

              this.tableData.push({
                ...item.data,
                id: item.id,
                statuses: item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration: item.type_demande.duration,
                type_demande: item.type_demande,
                process_steps: selectedForm.process_steps,
                reference: item.reference,
                curr_step,
                next_node,
                slug: selectedForm.slug,
              })
            })
            // console.log("this.tableData::: 🟠", this.tableData);
            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
                - this.paginationData.perPage
            }
            this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
              + res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    update_status(id, state, index) {
      let data = { status: state }

      const formData = new FormData()
      formData.append(`status`, state)

      if( this.tableData[index].curr_step.upload_files ){
        if(!this.allFilesAreUnempty){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vous n\'avez pas renseingé tous les fichiers demandés',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
          return 
        }
        let completed_attached_files_objects = []
        if(this.allFilesAreUnempty){
          for (let index = 0; index < this.upload_files_list.length; index++) {
            const file = this.upload_files_list[index];
            const curr_object = {
              file: file.value, key: file.key
            }
            completed_attached_files_objects.push((curr_object))
            formData.append(`completed_attached_files_${index+1}`, file.value)
          }
          formData.append(`files_number`, this.upload_files_list.length)

          // for (const pair of formData.entries()) {
          //   console.log(`${pair[0]}: ${pair[1]}`);
          // }
        }
      }
      // return
      this.isLoading_update_state = true
      this.action_updateFormulaireFormData({ 
        id, 
        data: this.tableData[index].curr_step.upload_files ? formData : data
      })
        .then(result => {
          // console.log('action_updateFormulaire result::: ', result);
          if (result.status === 200) {
            this.isLoading_update_state = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: "CheckIcon",              
                variant: 'success',
              },
            })
            this.init()
          }
        })
        .catch(err => {
          console.log('err::: ', err)
          this.isLoading_update_state = false
        })
    },

    fillForm() {
      if (!this.selectedDemandes) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez choisir une demande.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
      window.open(this.selectedDemandes.demande_link)
    },
    resetListWhenResetFilter() {
      this.filterBy = JSON.parse(
        JSON.stringfy({
          periode: null,
          reference: null,
          // identity: null,
        }),
      )
      this.getListDemandes()
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },
    onFilesComponentChange(val) {
      // load current file 
      for (let index = 0; index < val.length; index++) {
        const input_file = val[index];
        for (let index = 0; index < this.upload_files_list.length; index++) {
          const current_file = this.upload_files_list[index];
          if( input_file.key == current_file.key ) this.upload_files_list[index].value = input_file.value
        }
      }
      // console.log('this.upload_files_list::: 🟥', this.upload_files_list);      
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
