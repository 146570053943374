var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-preview-wrapper"},[_c('div',{staticClass:"position-relative"},[_c('b-card',{staticClass:"invoice-preview-card"},[_c('div',{staticClass:"d-flex flex-row flex-wrap row-gap-20"},[_c('h2',{staticClass:"my-auto col"},[_vm._v(" "+_vm._s(_vm.$t("request_list.title"))+" ")]),_c('b-button',{staticClass:"mt-1 float-right my-auto col-3",attrs:{"variant":"primary"},on:{"click":function () {
              this$1.$refs['modal-option-demandes'].show();
            }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("request_list.new_request")))])],1)],1),_c('hr',{}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"d-flex flex-row-reverse nopadding"},[_c('b-form-group',{staticClass:"col pr-0 pl-0",attrs:{"label":_vm.$t('request_list.filter_label_period')}},[_c('b-input-group',[_c('DateRangeWidget',{attrs:{"range":_vm.filterBy.periode,"placeholder":"Filtrer par date"},model:{value:(_vm.filterBy.periode),callback:function ($$v) {_vm.$set(_vm.filterBy, "periode", $$v)},expression:"filterBy.periode"}})],1)],1)],1)]),_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"d-flex flex-row-reverse nopadding"},[_c('b-form-group',{staticClass:"col pr-0 pl-0",attrs:{"label":"Rechercher par la référence de la demande","label":_vm.$t('request_list.filter_label_reference')}},[_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Entrer la référence de la demande. Exemple: qTkd5dp "},model:{value:(_vm.filterBy.reference),callback:function ($$v) {_vm.$set(_vm.filterBy, "reference", $$v)},expression:"filterBy.reference"}}),_c('b-input-group-append',{attrs:{"is-text":""},on:{"click":function () {
                      _vm.filterBy.reference = null;
                      _vm.resetListWhenResetFilter();
                    }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Vider le champ!'),expression:"'Vider le champ!'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":"XCircleIcon"}})],1)],1)],1)],1)]),_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row-reverse nopadding"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.getListDemandes()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"SearchIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.search")))])],1)],1)])],1)],1),_c('b-card',{staticClass:"mt-1"},[_c('div',{staticClass:"my-1"},[_c('b-table',{ref:"refFormsTable",attrs:{"items":_vm.tableData,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"Aucune donnée","busy":_vm.isLoading,"hover":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
                    var label = ref.label;
                    var ref_field = ref.field;
                    var key = ref_field.key;
                    var sortable = ref_field.sortable;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.created_at)+" ")]}},{key:"cell(reference)",fn:function(data){return [_c('span',{staticClass:"w-100",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(data.item.reference)}}},[_vm._v(" "+_vm._s(data.item.reference)+" ")])]}},{key:"cell(type_demande)",fn:function(data){return [_vm._v(" "+_vm._s((data.item.type_demande && data.item.type_demande.title) || data.item.type_demande_id)+" ")]}},{key:"cell(statuses)",fn:function(data){return [(!data.item.next_node)?_c('span',[_vm._v(" "+_vm._s(_vm.statusMatching( data.item.process_steps, data.item.statuses[0].name ))+" ")]):_c('span',[(data.item.next_node.duration)?_c('b-badge',{staticClass:"w-100 mb-1 badge-glow py-1",attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" En attent depuis "+_vm._s(_vm.calculate_passed_time_in_days( data.item.statuses[0].created_at ))+" jour(s) "),_c('br'),_vm._v(" Vous avez "+_vm._s(data.item.next_node.duration)+" jours pour valider cette étape. ")]):_vm._e(),(
                  !data.item.next_node.duration ||
                    (data.item.next_node.duration &&
                      _vm.calculate_passed_time_in_days(
                        data.item.statuses[0].created_at
                      ) <= data.item.next_node.duration)
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mb-1",attrs:{"variant":data.item.next_node.class,"size":"sm","block":""},on:{"click":function($event){return _vm.update_status(data.item.id, data.item.next_node.status, data.index)}}},[_c('span',[(!_vm.isLoading_update_state)?_c('span',[_vm._v(" "+_vm._s(data.item.next_node.action_label)+" ")]):_c('b-spinner',{attrs:{"small":""}})],1)]):_vm._e(),(data.item.curr_step.upload_files)?_c('span',[_c('hr',{staticClass:"hr-primary"}),_c('UploadFilesComponent',{attrs:{"files-list":data.item.curr_step.upload_files},on:{"change":_vm.onFilesComponentChange,"allFilesAreUnempty":function (event){_vm.allFilesAreUnempty = event},"allFilesAreValid":function (event){_vm.allFilesAreValid = event}}})],1):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('request_manage.details_tooltip')),expression:"$t('request_manage.details_tooltip')",modifiers:{"hover":true,"top":true}}],staticClass:"mx-0",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: 'formulaires-details-op',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug,
                  },
                })}}},[_c('i',{staticClass:"las la-eye"})])]}}])}),_c('div',[_c('PaginationComponent',{attrs:{"table-data":_vm.tableData,"per-page":_vm.paginationData.perPage,"total-rows":_vm.paginationData.total},on:{"change":_vm.onPaginationChange}})],1)],1)]),_c('b-modal',{ref:"modal-option-demandes",attrs:{"id":"modal-option-demandes","hide-backdrop":"","ok-only":"","no-close-on-backdrop":"","content-class":"shadow","title":"Quelle opération souhaitez-vous effectuer ?","ok-title":"Continuer"},on:{"ok":function($event){return _vm.fillForm()}}},[_c('b-form-group',{attrs:{"label":""}},[_c('b-form-radio-group',{model:{value:(_vm.selectedDemandes),callback:function ($$v) {_vm.selectedDemandes=$$v},expression:"selectedDemandes"}},_vm._l((_vm.optionsDemandes),function(demande,index){return _c('b-form-radio',{key:index,staticClass:"w-100",attrs:{"name":"demande","value":demande}},[_vm._v(" "+_vm._s(_vm.$t(demande.title))+" "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-primary",value:(demande.description),expression:"demande.description",modifiers:{"hover":true,"top":true,"v-primary":true}}],staticClass:"las la-info-circle ml-1"})])}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }